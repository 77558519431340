export const environment = {
  production: true,
  apiEndpoint: 'https://backend.tupelolife.health',
  appEndpoints: {
    patient: 'https://app.tupelolife.health',
    provider: 'https://provider.tupelolife.health',
    careteam: 'https://careteam.tupelolife.health',
    home_health: 'https://homehealth.tupelolife.health',
    admin: 'https://admin.tupelolife.health',
    super_admin: 'https://superadmin.tupelolife.health',
    super_super_admin: 'https://ssadmin.tupelolife.health'
  },
  pubnub: {
    publishKey: 'pub-c-27484b07-dd90-4a04-b288-623dcdf193c9',
    subscribeKey: 'sub-c-68ca4714-7744-11eb-9ab7-d2a9136758b1',
  },
};
